import React from "react";
import Sizes from "./sizes";
import { useState } from "react";

const isValidFieldValue = (value) => value && value?.length >= 2;
const isValidEmail = (email) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};
const isValidPhone = (phone) => {
  if (phone) {
    return phone.match(/\d/g).length > 5;
  }
  return false;
};

const isValidForm = (form) => {
  const { name, email, phone, message } = form;
  return (
    isValidFieldValue(name) &&
    isValidEmail(email) &&
    isValidPhone(phone) &&
    isValidFieldValue(message)
  );
};

const Contact = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleFormChange = (e) => {
    setForm({ ...form, [e?.target?.name]: e?.target?.value });
  };

  const validForm = isValidForm(form);
  return (
    <div id="#contact">
      <div className="container mx-auto">
        <div className="mt-2 md:mb-20 mb-10">
          <hr className="text-neutral-300"></hr>
        </div>
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-12">
          <div className="lg:col-span-6 md:pr-24">
            <h3 className="font-display md:text-display-lg text-display-sm font-normal pb-8">
              Open to booking opportunities
            </h3>
            <div>
              <Sizes />
            </div>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 xl:pl-70 lg:pl-40">
            <div className="lg:col-span-6 m d:pr-24">
              <h3 className="font-display md:text-display-lg text-display-sm text-primary-600 ">
                Say hi!
              </h3>
            </div>

            <p>
              <span className="text-primary-600">*</span>All fields are required
            </p>
            <div className="flex flex-col gap-2">
              <form
                className="contact-form"
                data-netlify="true"
                name="contact"
                method="POST"
                netlify-honeypot="bot-field"
                action="/thank-you"
              >
                <input type="hidden" name="form-name" value="contact" />
                <input hidden name="bot-field" />
                <div className="flex flex-col gap-2 mb-4">
                  <label htmlFor="name">
                    Name <span className="text-primary-600">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="appearance-none bg-transparent border-b w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    id="name"
                    required
                    value={form.name}
                    onChange={handleFormChange}
                  />
                </div>
                <div className="flex flex-col gap-2 mb-4">
                  <label htmlFor="email">
                    Email <span className="text-primary-600">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="appearance-none bg-transparent border-b w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    id="email"
                    required
                    value={form.email}
                    onChange={handleFormChange}
                  />
                </div>
                <div className="flex flex-col gap-2 mb-4">
                  <label htmlFor="phone">
                    Phone <span className="text-primary-600">*</span>
                  </label>
                  <input
                    type="phone"
                    name="phone"
                    className="appearance-none bg-transparent border-b w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    id="phone"
                    required
                    value={form.phone}
                    onChange={handleFormChange}
                  />
                </div>
                <div className="flex flex-col gap-2 mb-4">
                  <label htmlFor="message">
                    Message <span className="text-primary-600">*</span>
                  </label>
                  <textarea
                    name="message"
                    className="appearance-none bg-transparent border-b w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    id="message"
                    required
                    value={form.message}
                    onChange={handleFormChange}
                  ></textarea>
                </div>
                <div className="flex flex-col gap-2 mt-8 w-1/3">
                  <button
                    disabled={!validForm}
                    type="submit"
                    className="button flex gap-1 items-center justify-center bg-primary-600 hover:bg-primary-300 text-white font-semibold px-6 py-4 text-body-sm cursor-pointer disabled:bg-primary-300 disabled:cursor-not-allowed"
                  >
                    SEND
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
