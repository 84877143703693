import React from "react";
import Eyebrow from "./eyebrow";
import { demoReel } from "../constants/constants";

const Reels = () => {
  return (
    <div id="#reel">
      <div className="container mx-auto">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-28 md:py-20 py-12">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div className="lg:col-span-8">
              <Eyebrow label="DEMO REEL" />
            </div>
          </div>
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              src={demoReel.url}
              allow="fullscreen; picture-in-picture"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title={demoReel.title}
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>
    </div>
  );
};
export default Reels;
