import React from "react";

export const sizeMapping = (label, value) => {
  return (
    <tr key={label}>
      <td>
        <p className="text-body font-normal text-neutral-900">{label}</p>
      </td>
      <td>
        <hr className="w-6 text-neutral-700 ml-6 mr-8"></hr>
      </td>
      <td>
        <p className="text-body font-light text-neutral-900">{value}</p>
      </td>
    </tr>
  );
};

export const demoReel = {
  url: "https://player.vimeo.com/video/853893056?h=1adf810abe&amp;title=0&amp;speed=0&amp;pip=0&amp;quality=undefined&amp;app_id=122963&amp;playsinline=0&amp;byline=0&amp;portrait=0",
  title: "Ozz Gomez - Demo Reel 2023",
};

export const sizeValues = [
  { label: "Height", value: `6'0"` },
  { label: "Chest", value: `42` },
  { label: "Neck", value: `16` },
  { label: "Sleeve", value: `34` },
  { label: "Coat", value: `Regular` },
  { label: "Shoe", value: `10` },
  { label: "Waist", value: `36"` },
  { label: "Inseam", value: `32"` },
];
