import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Eyebrow from "./eyebrow";

import Gallery from "@browniebroke/gatsby-image-gallery";

const Headshots = () => {
  const data = useStaticQuery(graphql`
    {
      images: allFile(filter: { relativeDirectory: { eq: "headshots" } }) {
        edges {
          node {
            childImageSharp {
              thumb: gatsbyImageData(
                width: 270
                height: 270
                placeholder: BLURRED
              )
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  const images = data.images.edges.map(({ node }, index) => ({
    ...node.childImageSharp,
    // Generate name based on the index as caption.
    caption: `Image ${index}`,
  }));

  // Override some of Lightbox options to localise labels in French
  // const lightboxOptions = {
  //   imageLoadErrorMessage: "Impossible de charger cette image",
  //   nextLabel: "Image suivante",
  //   prevLabel: "Image précédente",
  //   zoomInLabel: "Zoomer",
  //   zoomOutLabel: "Dézoomer",
  //   closeLabel: "Fermer",
  // };

  //Add callback to Lightbox onCloseRequest
  const onClose = () => {
    console.log("Lightbox was closed");
  };

  return (
    <div id="#headshots">
      <div className="container mx-auto">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-28 md:py-20 py-12">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div className="lg:col-span-8">
              <Eyebrow label="HEADSHOTS" />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col">
            <Gallery
              images={images}
              // lightboxOptions={lightboxOptions}
              onClose={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Headshots;
