import React from "react";
import { sizeMapping, sizeValues } from "../constants/constants";

const Sizes = () => {
  return (
    <table className="table-auto">
      <tbody>
        {sizeValues.map(({ label, value }) => sizeMapping(label, value))}
      </tbody>
    </table>
  );
};

export default Sizes;
