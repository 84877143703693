import React from "react";

import Seo from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Metrics from "../components/metrics";
import Reels from "../components/reels";
//import Awards from "../components/awards";
import About from "../components/about";
import Works from "../components/works";
import Headshots from "./../components/headshots";
import Contact from "./../components/contact";

const IndexPage = () => (
  <Layout>
    <Seo />
    <Hero />
    {/* <Awards /> */}
    <About />
    <Metrics />
    <Reels />
    <Headshots />
    <Works />
    <Contact />
  </Layout>
);

export default IndexPage;
