import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";
import LogoBlack from "../images/logos/logo_black.svg";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "about-01.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      aboutimage2: file(relativePath: { eq: "about-02.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div id="#bio">
      <div className="container mx-auto mb-8">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="BIO" />
            <h2 className="font-display md:text-display-xl text-display-md font-normal pb-4">
              Ozz Gomez is a Dominican American actor, producer and
              screenwriter.
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Ozz is best known for national commercials and cowriting The Mick
              and The Trick action comedy, portraying the character Vega
              opposite Peter Greene. Born Osvaldo Gomez-Molina in the Dominican
              Republic, Ozz immigrated to the United States at the age of six.
              He graduated Pennsylvania State University in Marketing &
              Management and went on to have a promising career doing
              International Anti Money Laundering with interest in high valued
              persons for Merrill Lynch Bank of America before stumbling into
              acting. Since dedicating his time to acting, he has worked in over
              50 projects from television series, films and commercials which
              include Free Meek, Kitchen Aid, Southwest Airlines, Steak Umms,
              Septa and The Killer Closer. His early acting training includes
              advanced classes at the Walnut Street Theatre in downtown
              Philadelphia. His training and experience varies from stage
              combat. Fluent in Spanish. Skilled in weapons training due to his
              time as a competitive shooter.
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              In March 2020, at the start of the Covid19 pandemic, he co-founded{" "}
              <a
                href="https://www.outhouseproductionfilms.com/"
                className="text-primary-600 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Outhouse Production Films
              </a>
              , producing to date, two full length motion pictures and several
              SAG-Aftra award winning short films.
            </p>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 relative">
            <div className="grid grid-flow-col grid-cols-2 gap-8">
              <GatsbyImage
                image={getImage(data.aboutimage)}
                alt="Ozz Gomez in black suit adjusting watch"
              />
              <div className="transform translate-y-12">
                <GatsbyImage
                  image={getImage(data.aboutimage2)}
                  alt="Ozz Gomez in black suit sitting"
                />
              </div>
            </div>
            <img
              src={LogoBlack}
              alt="Ozz Gomez Logo Black"
              className="max-w-xs md:absolute md:left-[42%] md:-top-22"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
