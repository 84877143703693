import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      heroimage: file(relativePath: { eq: "hero-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 6720
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div>
      <div className="relative flex justify-center mx-auto">
        <GatsbyImage
          image={getImage(data.heroimage)}
          alt="Ozz Gomez headshot"
        />
      </div>
    </div>
  );
};

export default Hero;
