import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { Link } from "react-scroll";

import WorkItem from "./workItem";
import Eyebrow from "./eyebrow";
import Button from "./button";

const Works = () => {
  const data = useStaticQuery(graphql`
    {
      allWorksJson {
        nodes {
          id
          title
          image {
            childImageSharp {
              gatsbyImageData(
                width: 592
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);
  return (
    <div id="#works">
      <div className="container mx-auto">
        <div className="flex flex-col gap-12 lg:py-28 md:py-24 py-12">
          <div className="grid xl:grid-cols-12 grid-cols-1 xl:gap-8 gap-10 items-center">
            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:gap-24 md:gap-20 gap-10">
              <div className="flex flex-col gap-6">
                <Eyebrow label="RECENT WORKS" />
              </div>
              {data.allWorksJson.nodes.slice(0, 1).map((node) => (
                <WorkItem
                  key={node.id}
                  image={getImage(node.image)}
                  title={node.title}
                />
              ))}
              <div className="xl:flex hidden items-start">
                <Link
                  spy={true}
                  smooth={true}
                  duration={500}
                  key={"contact"}
                  to={"#contact"}
                >
                  <Button label="CONTACT US" size="lg"></Button>
                </Link>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:gap-24 md:gap-20 gap-10 xl:px-14">
              {data.allWorksJson.nodes.slice(1, 3).map((node) => (
                <WorkItem
                  key={node.id}
                  image={getImage(node.image)}
                  title={node.title}
                />
              ))}
            </div>
          </div>
          <div className="xl:hidden flex items-start">
            <Link
              spy={true}
              smooth={true}
              duration={500}
              key={"contact"}
              to={"#contact"}
            >
              <Button label="CONTACT US" size="lg"></Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
